import isEmpty from 'lodash/isEmpty';

export function hasDeviceChannelSettings(device) {
  if (!device) {
    return false;
  }

  if (!device.data) {
    return false;
  }
  if (isEmpty(device.data.settings)) {
    return false;
  }

  if (
    !device.data.settings.ch1 &&
    !device.data.settings.ch2 &&
    !device.data.settings.ch3 &&
    !device.data.settings.ch4
  ) {
    return false;
  }

  return true;
}

export function hasDeviceManufacturer(device) {
  if (!device) {
    return false;
  }

  if (!device.data) {
    return false;
  }
  if (isEmpty(device.data.settings)) {
    return false;
  }

  if (device.data.settings.mfr && device.data.settings.mfr > 0) {
    return true;
  }

  return false;
}

export function hasDeviceConfigCalc(device) {
  if (!device) {
    return false;
  }

  if (!device.config) {
    return false;
  }
  if (isEmpty(device.config.config)) {
    return false;
  }

  if (isEmpty(device.config.config.calc)) {
    return false;
  }

  return true;
}

export function isTemperatureAndHumiditySensor(device) {
  if (!device) {
    return false;
  }
  if (!device.data || isEmpty(device.data.settings)) {
    return false;
  }

  // check if mfr is Efento (3) or comtac (6)
  if (device.data.settings.mfr === 3 || device.data.settings.mfr === 6) {
    // check if at least one of two channels has a sensor type of 2 / 25 (humidity) and 1 (temperature)
    const temperature =
      device.data.settings.ch1 === 1 ||
      device.data.settings.ch2 === 1 ||
      device.data.settings.ch3 === 1 ||
      device.data.settings.ch4 === 1;
    const humidity =
      device.data.settings.ch1 === 2 ||
      device.data.settings.ch2 === 2 ||
      device.data.settings.ch3 === 2 ||
      device.data.settings.ch4 === 2 ||
      device.data.settings.ch1 === 25 ||
      device.data.settings.ch2 === 25 ||
      device.data.settings.ch3 === 25 ||
      device.data.settings.ch4 === 25;

    return temperature && humidity;
  }

  return false;
}

export function isHumiditySensor(device) {
  if (!device) {
    return false;
  }
  if (!device.data || isEmpty(device.data.settings)) {
    return false;
  }

  // check if mfr is Efento (3)
  if (device.data.settings.mfr === 3) {
    // check if at least one of two channels has a sensor type of 2 / 25 (humidity)
    const humidity =
      device.data.settings.ch1 === 2 ||
      device.data.settings.ch2 === 2 ||
      device.data.settings.ch3 === 2 ||
      device.data.settings.ch4 === 2 ||
      device.data.settings.ch1 === 25 ||
      device.data.settings.ch2 === 25 ||
      device.data.settings.ch3 === 25 ||
      device.data.settings.ch4 === 25;

    return humidity;
  }

  return false;
}

export function isAnalogGateway(device) {
  if (!device) {
    return false;
  }
  if (!device.data || isEmpty(device.data.settings)) {
    return false;
  }

  // check if mfr is Efento (3) or IOTROUTER (4)
  if (device.data.settings.mfr === 3 || device.data.settings.mfr === 4) {
    // check if at least one of two channels has a sensor type of 31 (CURRENT) or 32 (CURRENT_PRECISE)
    const analog =
      device.data.settings.ch1 === 31 ||
      device.data.settings.ch2 === 31 ||
      device.data.settings.ch3 === 31 ||
      device.data.settings.ch4 === 31 ||
      device.data.settings.ch1 === 32 ||
      device.data.settings.ch2 === 32 ||
      device.data.settings.ch3 === 32 ||
      device.data.settings.ch4 === 32;

    return analog;
  }

  return false;
}

export function isCountecDevice(device) {
  if (!device) {
    return false;
  }

  if (!device.data || isEmpty(device.data.settings)) {
    return false;
  }

  // check if mfr is Countec (7)
  if (device.data.settings.mfr === 7) {
    return true;
  }

  return false;
}

export function isComtacDevice(device) {
  if (!device) {
    return false;
  }

  if (!device.data || isEmpty(device.data.settings)) {
    return false;
  }

  // check if mfr is Comtac (6)
  if (device.data.settings.mfr === 6) {
    return true;
  }

  return false;
}

export function isLupusDevice(device) {
  if (!device) {
    return false;
  }

  if (!device.data || isEmpty(device.data.settings)) {
    return false;
  }

  // check if mfr is Lupus (10)
  if (device.data.settings.mfr === 10) {
    return true;
  }

  return false;
}

export function isEfentoDevice(device) {
  if (!device) {
    return false;
  }

  if (!device.data || isEmpty(device.data.settings)) {
    return false;
  }

  // check if mfr is Efento (3)
  if (device.data.settings.mfr === 3) {
    return true;
  }

  return false;
}

export function isEfentoNBioT(device) {
  if (!isEfentoDevice(device)) {
    return false;
  }

  return device.data && device.data.settings && device.data.settings.dt === 1;
}

export function isEfentoBLE(device) {
  if (!isEfentoDevice(device)) {
    return false;
  }

  return device.data && device.data.settings && device.data.settings.dt === 2;
}

export function isHikvisionDevice(device) {
  if (!device) {
    return false;
  }

  return device.data && device.data.settings && device.data.settings.mfr === 8;
}

export function isCurrentType(type) {
  return type === 31 || type === 32;
}

export const MeasurementType = {
  0: 'NO_SENSOR',
  1: 'TEMPERATURE',
  2: 'HUMIDITY',
  3: 'ATMOSPHERIC_PRESSURE',
  4: 'DIFFERENTIAL_PRESSURE',
  5: 'OK_ALARM',
  6: 'IAQ',
  7: 'FLOODING',
  8: 'PULSE_CNT',
  9: 'ELECTRICITY_METER',
  10: 'WATER_METER',
  11: 'SOIL_MOISTURE',
  12: 'CO_GAS',
  13: 'NO2_GAS',
  14: 'H2S_GAS',
  15: 'AMBIENT_LIGHT',
  16: 'PM_1_0',
  17: 'PM_2_5',
  18: 'PM_10_0',
  19: 'NOISE_LEVEL',
  20: 'NH3_GAS',
  21: 'CH4_GAS',
  22: 'HIGH_PRESSURE',
  23: 'DISTANCE_MM',
  24: 'CO2_GAS',
  25: 'HUMIDITY_ACCURATE',
  26: 'STATIC_IAQ',
  27: 'CO2_EQUIVALENT',
  28: 'BREATH_VOC',
  29: 'PERCENTAGE',
  30: 'VOLTAGE',
  31: 'CURRENT',
  32: 'CURRENT_PRECISE',
  33: 'OUTPUT_CONTROL',
};

function stringToNumber(value) {
  if (typeof value === 'string') {
    const val = value.trimStart().trimEnd();
    if (!Number.isNaN(parseFloat(val)) && !Number.isNaN(Number(val))) {
      return Number(val);
    }
    return val;
  }
  return value;
}

function sanitizeConfigChannel(channel) {
  if (!channel) {
    return channel;
  }

  return Object.keys(channel).reduce((acc, key) => {
    acc[key] = stringToNumber(channel[key]);
    return acc;
  }, {});
}

export function sanitizeConfig(config) {
  if (!config || isEmpty(config.config)) {
    return config;
  }

  if (!isEmpty(config.config.ch1)) {
    // eslint-disable-next-line no-param-reassign
    config.config.ch1 = sanitizeConfigChannel(config.config.ch1);
  }
  if (!isEmpty(config.config.ch2)) {
    // eslint-disable-next-line no-param-reassign
    config.config.ch2 = sanitizeConfigChannel(config.config.ch2);
  }
  if (!isEmpty(config.config.ch3)) {
    // eslint-disable-next-line no-param-reassign
    config.config.ch3 = sanitizeConfigChannel(config.config.ch3);
  }
  if (!isEmpty(config.config.ch4)) {
    // eslint-disable-next-line no-param-reassign
    config.config.ch4 = sanitizeConfigChannel(config.config.ch4);
  }

  return config;
}

const deviceManufacturers = () => ({
  1: { label: 'WoMaster' },
  2: { label: 'Teltonika' },
  3: { label: 'Efento' },
  4: { label: 'IOTRouter' },
  5: { label: 'Laird/ezurio' },
  6: { label: 'comtac' },
  7: { label: 'CounTec' },
  8: { label: 'Hikvision' },
  9: { label: 'Wallatec' },
  10: { label: 'Lupus' },
});

export function getManufacturerName(mfrNumber) {
  const mfr = deviceManufacturers()[mfrNumber];
  if (!mfr) {
    return '';
  }
  return mfr.label;
}

export const getDeviceSettingsOptions = () => {
  const categories = deviceManufacturers();
  return Object.entries(categories).map(([key, category]) => ({
    label: category.label,
    value: Number(key),
  }));
};

const deviceTypes = () => ({
  1: { label: 'NB-IoT' },
  2: { label: 'BLE' },
  3: { label: 'LTE' },
});

export function getDeviceType(dt) {
  const deviceType = deviceTypes()[dt];
  if (!deviceType) {
    return '';
  }
  return deviceType.label;
}

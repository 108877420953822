import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import querystring from 'query-string';
import { defineMessages } from 'react-intl';
import { getAdminEntities, isAdminEntity, isMemberEntity } from './jwt';

export const DISTRIBUTOR = 'DISTRIBUTOR';
export const ACCOUNT = 'ACCOUNT';
export const UNDEFINED = 'UNDEFINED';
export const ASSET = 'ASSET';

export const messages = defineMessages({
  DISTRIBUTOR: {
    id: 'Entity.DISTRIBUTOR',
    defaultMessage: 'Distributor',
  },
  ACCOUNT: {
    id: 'Entity.ACCOUNT',
    defaultMessage: 'Account',
  },
  UNDEFINED: {
    id: 'Entity.UNDEFINED',
    defaultMessage: 'Undefined',
  },
  ASSET: {
    id: 'Entity.ASSET',
    defaultMessage: 'Asset',
  },
  DEVICE: {
    id: 'LABEL.DEVICE',
    defaultMessage: 'Device',
  },
  ENTITY_TYPE: {
    id: 'Entity.ENTITY_TYPE',
    defaultMessage: 'Entity Type',
  },
  IS_EXPANDED: {
    id: 'Entity.IS_EXPANDED',
    defaultMessage: 'Is Expanded',
  },
  IS_RESTRICTED: {
    id: 'Entity.IS_RESTRICTED',
    defaultMessage: 'Restricted',
  },
});

function tree(entities, devices, admin_entities, filter_root_entity_id = null) {
  const data = Object.values(entities);
  return data
    .filter((node) => (filter_root_entity_id ? node.id === filter_root_entity_id : !node.parent_id))
    .map((child) => {
      const can_admin = isEntityAdmin(entities, child.id, admin_entities);
      // eslint-disable-next-line no-param-reassign
      child.children = [
        ...traverse(entities, data, devices, child.id, admin_entities, can_admin),
        ...getDevices(
          devices,
          child.id,
          isEntityAdminForEntityType(entities, child.id, DISTRIBUTOR, admin_entities) ||
            isEntityAdminForEntityType(entities, child.id, ACCOUNT, admin_entities)
        ),
      ];
      // eslint-disable-next-line no-param-reassign
      child.key = child.id;
      // eslint-disable-next-line no-param-reassign
      child.draggable = false; // restrict all root entities
      // eslint-disable-next-line no-param-reassign
      child.can_admin = can_admin;
      // eslint-disable-next-line no-param-reassign
      child.style = { padding: 0, paddingBottom: '.1rem' };
      return child;
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

function traverse(entities, data, devices, parentId, admin_entities, parent_can_admin) {
  const children = data.filter((each) => each.parent_id === parentId);
  children.forEach((child) => {
    const can_admin = isEntityAdmin(entities, child.id, admin_entities);
    // eslint-disable-next-line no-param-reassign
    child.children = [
      ...traverse(entities, data, devices, child.id, admin_entities, can_admin),
      ...getDevices(
        devices,
        child.id,
        isEntityAdminForEntityType(entities, child.id, DISTRIBUTOR, admin_entities) ||
          isEntityAdminForEntityType(entities, child.id, ACCOUNT, admin_entities)
      ),
    ];
    // eslint-disable-next-line no-param-reassign
    child.key = child.id;
    if (child.is_restricted) {
      // eslint-disable-next-line no-param-reassign
      child.draggable = false;
    } else {
      // eslint-disable-next-line no-param-reassign
      child.draggable = parent_can_admin;
    }
    // eslint-disable-next-line no-param-reassign
    child.can_admin = can_admin;
    // eslint-disable-next-line no-param-reassign
    child.style = { padding: 0, paddingBottom: '.1rem' };
    // child.style = { padding: 0, paddingBottom: '.25rem', paddingLeft: '.25rem' };
  });
  return children.sort((a, b) => a.name.localeCompare(b.name));
}

function getDevices(devices, entity_id, can_admin) {
  if (devices && devices.length) {
    return devices
      .filter((d) => d.entity_id === entity_id)
      .map((d) => ({
        ...d,
        key: d.id,
        entity_type: 'DEVICE',
        can_admin: can_admin,
        has_error: configHasErrors(getCurrentConfigState(d)[1]),
        has_invalid_measurements: hasCurrentInvalidMeasurementState(d),
        fw_version: getCurrentFWVersion(
          getCurrentDeviceInfoState(d)[1] || getCurrentLupusDeviceInfoState(d)[1]
        ),
        battery_state: getCurrentBatteryState(d),
        device_info: getCurrentDeviceInfoState(d)[1],
        draggable: can_admin,
        droppable: false,
        optional_sensor_id: d.config && d.config.optional_sensor_id,
        style: { padding: 0, paddingBottom: '.1rem' },
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return [];
}

export function createEntityTree(entities, devices, filter_root_entity_id) {
  if (!isEmpty(entities)) {
    if (!devices) {
      // eslint-disable-next-line no-param-reassign
      devices = {};
    }
    return tree(entities, Object.values(devices), getAdminEntities(), filter_root_entity_id);
  }
  return [];
}

export function getRootEntityIDFromQuery(entities, search) {
  if (!isEmpty(entities)) {
    if (search && search.length > 0) {
      const queryParams = querystring.parse(search);
      if (queryParams && queryParams.r && queryParams.r.length > 0 && entities[queryParams.r]) {
        return queryParams.r;
      }
    }
  }
  return null;
}

export function getParentIDFromEntityID(entities, entity_id) {
  if (!isEmpty(entities)) {
    if (entities[entity_id] && entities[entity_id].parent_id) {
      return entities[entity_id].parent_id;
    }
  }
  return null;
}

export function getExpandedKeys(entities, filter_root_entity_id) {
  const expandedKeys = {};
  if (!isEmpty(entities)) {
    Object.values(entities).forEach((e) => {
      if (e && e.config && e.config.is_expanded) {
        expandedKeys[e.id] = true;
      }
    });
    // check if only one root element
    const rootElements = Object.values(entities).filter((r) => !r.parent_id);
    if (rootElements.length === 1) {
      expandedKeys[rootElements[0].id] = true;
    }
    if (filter_root_entity_id) {
      expandedKeys[filter_root_entity_id] = true;
    }
  }
  return expandedKeys;
}

function hasEntityTypeMatch(entities, entity_id, entity_type) {
  const entity = entities[entity_id];
  if (entity) {
    if (entity.entity_type === entity_type) {
      return true;
    }
    if (entity.parent_id && entities[entity.parent_id]) {
      return hasEntityTypeMatch(entities, entity.parent_id, entity_type);
    }
  }
  return false;
}

export function hasParentEntityType(entities, entity_id, parent_entity_type) {
  // loop all parent entities
  return hasEntityTypeMatch(entities, entity_id, parent_entity_type);
}

function hasMatchReturnMatch(entities, entity_id, entity_type) {
  const entity = entities[entity_id];
  if (entity) {
    if (entity.entity_type === entity_type) {
      return entity;
    }
    if (entity.parent_id && entities[entity.parent_id]) {
      return hasMatchReturnMatch(entities, entity.parent_id, entity_type);
    }
  }
  return null;
}

export function hasParentMMSAccountEntity(entities, entity_id) {
  const match = hasMatchReturnMatch(entities, entity_id, ACCOUNT);
  if (match && match.data && match.data.has_mms) {
    return true;
  }
  return false;
}

export function getParentAccountEntity(entities, entity_id) {
  return hasMatchReturnMatch(entities, entity_id, ACCOUNT);
}

export function getParentDistributorEntity(entities, entity_id) {
  return hasMatchReturnMatch(entities, entity_id, DISTRIBUTOR);
}

function hasAdminEntityMatch(entities, entity_id, admin_entities) {
  const entity = entities[entity_id];
  if (entity && admin_entities && admin_entities.length) {
    if (admin_entities.includes(entity.id)) {
      return true;
    }
    if (entity.parent_id && entities[entity.parent_id]) {
      return hasAdminEntityMatch(entities, entity.parent_id, admin_entities);
    }
  }
  return false;
}

export function isEntityAdmin(entities, entity_id, admin_entities) {
  if (!admin_entities || !admin_entities.length) {
    // eslint-disable-next-line no-param-reassign
    admin_entities = getAdminEntities();
  }
  if (admin_entities && admin_entities.length) {
    return hasAdminEntityMatch(entities, entity_id, admin_entities);
  }
  return false;
}

function hasEntityAdminForEntityType(entities, entity_id, admin_entities, entity_type) {
  const entity = entities[entity_id];
  if (entity && admin_entities && admin_entities.length) {
    if (entity.entity_type === entity_type && admin_entities.includes(entity.id)) {
      return true;
    }
    if (entity.parent_id && entities[entity.parent_id]) {
      return hasEntityAdminForEntityType(entities, entity.parent_id, admin_entities, entity_type);
    }
  }
  return false;
}

export function isEntityAdminForEntityType(entities, entity_id, entity_type, admin_entities) {
  if (!admin_entities || !admin_entities.length) {
    // eslint-disable-next-line no-param-reassign
    admin_entities = getAdminEntities();
  }
  if (admin_entities && admin_entities.length) {
    return hasEntityAdminForEntityType(entities, entity_id, admin_entities, entity_type);
  }
  return false;
}

export function hasEntityTypeNonRecursive(entities, entity_type) {
  let match = false;
  if (entities) {
    Object.values(entities).forEach((e) => {
      if (match) {
        return;
      }
      if (e.entity_type === entity_type) {
        match = true;
      }
    });
  }

  return match;
}

function hasMMSAdminEntityTypeMatch(entities, entity_id, admin_entities, entity_type) {
  const entity = entities[entity_id];
  if (entity && admin_entities && admin_entities.length) {
    if (
      entity.entity_type === entity_type &&
      entity.data.has_mms &&
      admin_entities.includes(entity.id)
    ) {
      return true;
    }
    if (entity.parent_id && entities[entity.parent_id]) {
      return hasMMSAdminEntityTypeMatch(entities, entity.parent_id, admin_entities, entity_type);
    }
  }
  return false;
}

export function hasParentMMSAdminEntityTypeMatch(entities, entity_id, admin_entities, entity_type) {
  if (!admin_entities || !admin_entities.length) {
    // eslint-disable-next-line no-param-reassign
    admin_entities = getAdminEntities();
  }
  if (admin_entities && admin_entities.length) {
    return hasMMSAdminEntityTypeMatch(entities, entity_id, admin_entities, entity_type);
  }
  return false;
}

export function hasAdminMMSEntityNonRecursive(entities, admin_entities) {
  if (!admin_entities || !admin_entities.length) {
    // eslint-disable-next-line no-param-reassign
    admin_entities = getAdminEntities();
  }
  let match = false;
  if (admin_entities && admin_entities.length) {
    Object.values(entities).forEach((e) => {
      if (match) {
        return;
      }
      if (e.entity_type === ACCOUNT && e.data.has_mms && admin_entities.includes(e.id)) {
        match = true;
      }
    });
  }

  return match;
}

export function hasEntityByRegistrationToken(entities, registration_code) {
  const entity = findEntityByToken(entities, registration_code);
  if (entity) {
    return isMemberEntity(entity.id) || isAdminEntity(entity.id);
  }

  return false;
}

function findEntityByToken(entities, registration_code) {
  return Object.values(entities).find(
    (e) => e.data && e.data.registration_code === registration_code
  );
}

export function isAccount(entity) {
  return entity && entity.entity_type === ACCOUNT;
}

export function isDistributor(entity) {
  return entity && entity.entity_type === DISTRIBUTOR;
}

export function isAsset(entity) {
  return entity && entity.entity_type === ASSET;
}

export function isUndefined(entity) {
  return entity && entity.entity_type === UNDEFINED;
}

export function isDevice(entity) {
  return entity && entity.entity_type === 'DEVICE';
}

export function getEntityTypeName(entity, formatMessage) {
  if (isAccount(entity)) {
    return formatMessage(messages.ACCOUNT);
  }
  if (isDistributor(entity)) {
    return formatMessage(messages.DISTRIBUTOR);
  }
  if (isAsset(entity)) {
    return formatMessage(messages.ASSET);
  }
  if (isUndefined(entity)) {
    return formatMessage(messages.UNDEFINED);
  }
  if (isDevice(entity)) {
    return formatMessage(messages.DEVICE);
  }
  return '';
}

export function isDeviceOnline(device, overwrite_offline_time = null) {
  if (device && device.last_seen) {
    let is_lupus = false;
    if (device && device.internal_name && device.internal_name.startsWith('898822800000')) {
      is_lupus = true;
    }

    const last_seen_sec = (new Date().getTime() - new Date(device.last_seen).getTime()) / 1000;

    const config = getCurrentConfigState(device)[1];
    if (config && config.transmission_interval && config.transmission_interval > 0) {
      if (last_seen_sec < config.transmission_interval * 1.1) {
        return [true, null];
      }
    }

    let offline_time = 15; // minuten
    if (is_lupus) {
      // 10 days in minutes (maximum time for smoke detector to send heartbeat)
      offline_time = 10 * 24 * 60;
    }
    if (
      overwrite_offline_time != null &&
      overwrite_offline_time !== '' &&
      !Number.isNaN(Number(overwrite_offline_time))
    ) {
      offline_time = Number(overwrite_offline_time);
    } else if (
      !is_lupus &&
      device.config &&
      device.config.offline_time !== '' &&
      !Number.isNaN(Number(device.config.offline_time))
    ) {
      offline_time = Number(device.config.offline_time);
    }
    if (last_seen_sec / 60 < offline_time) {
      return [true, null];
    }
    return [false, last_seen_sec];
  }

  return [false, null];
}

export function getCurrentBatteryState(device) {
  if (device && device.current_state && device.current_state.battery) {
    // loop over all battery states and return the most recent one
    let current_state_timestamp = null;
    let has_low_battery = false;

    const arr_length = Object.keys(device.current_state.battery).length;
    let idx_for_low_battery = 0;
    if (arr_length <= 3) {
      idx_for_low_battery = 0;
    } else {
      idx_for_low_battery = arr_length - 3;
    }

    Object.keys(device.current_state.battery).forEach((state_timestamp, idx) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
      if (!has_low_battery && device.current_state.battery[state_timestamp] === 'low') {
        // check if current idx is bigger than idx_for_low_battery
        if (idx >= idx_for_low_battery) {
          has_low_battery = true;
        }
      }
    });
    if (current_state_timestamp) {
      return [
        current_state_timestamp,
        device.current_state.battery[current_state_timestamp],
        has_low_battery,
      ];
    }
  } else if (device && device.current_state && device.current_state.lupus) {
    // loop over all battery states and return the most recent one
    const [current_state_timestamp, lupus_device_info] = getCurrentLupusDeviceInfoState(device);
    if (lupus_device_info) {
      if (lupus_device_info.batteryState === 'ERROR') {
        return [current_state_timestamp, lupus_device_info, true];
      }
      if (lupus_device_info.batteryLevel < 2.7 && lupus_device_info.batteryLevel > 1) {
        return [current_state_timestamp, lupus_device_info, true];
      }
      return [current_state_timestamp, lupus_device_info, false];
    }
  }

  return [null, null, false];
}

export function getCurrentDeviceInfoState(device) {
  if (device && device.current_state && device.current_state.device_info) {
    // loop over all device info states and return the most recent one
    let current_state_timestamp = null;
    Object.keys(device.current_state.device_info).forEach((state_timestamp) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
    });
    if (current_state_timestamp) {
      return [current_state_timestamp, device.current_state.device_info[current_state_timestamp]];
    }
  }

  return [null, null];
}

export function getCurrentLupusDeviceInfoState(device) {
  if (device && device.current_state && device.current_state.lupus) {
    // loop over all device info states and return the most recent one
    let current_state_timestamp = null;
    Object.keys(device.current_state.lupus).forEach((state_timestamp) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
    });
    if (current_state_timestamp) {
      return [current_state_timestamp, device.current_state.lupus[current_state_timestamp]];
    }
  }

  return [null, null];
}

export function getCurrentRelayState(device) {
  // if (device && device.current_state && device.current_state.relay_state) {
  //   // TODO: legacy, remove when all devices are migrated
  //   // loop over all relay states and return the most recent one
  //   let current_state_timestamp = null;
  //   Object.keys(device.current_state.relay_state).forEach((state_timestamp) => {
  //     if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
  //       current_state_timestamp = state_timestamp;
  //     }
  //   });
  //   if (current_state_timestamp) {
  //     return [current_state_timestamp, device.current_state.relay_state[current_state_timestamp]];
  //   }
  // }
  if (device && device.current_state && device.current_state.alert_device) {
    // loop over all relay states and return the most recent one
    let current_state_timestamp = null;
    Object.keys(device.current_state.alert_device).forEach((state_timestamp) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
    });
    if (current_state_timestamp) {
      // timestamp is in nanoseconds
      return [current_state_timestamp, device.current_state.alert_device[current_state_timestamp]];
    }
  }

  return [null, null];
}

export function getCurrentConfigState(device) {
  if (device && device.current_state && device.current_state.config) {
    // loop over all config states and return the most recent one
    let current_state_timestamp = null;
    Object.keys(device.current_state.config).forEach((state_timestamp) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
    });
    if (current_state_timestamp) {
      return [current_state_timestamp, device.current_state.config[current_state_timestamp]];
    }
  }

  return [null, null];
}

export function getCurrentBLEState(device) {
  if (device && device.current_state && device.current_state.ble_state) {
    // loop over all ble states and return the most recent one
    let current_state_timestamp = null;
    Object.keys(device.current_state.ble_state).forEach((state_timestamp) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
    });
    if (current_state_timestamp) {
      return [current_state_timestamp, device.current_state.ble_state[current_state_timestamp]];
    }
  }

  return [null, null];
}

export function getNewConfigState(device) {
  if (device && device.current_state && device.current_state.new_config) {
    if (!isEmpty(device.current_state.new_config) && isObject(device.current_state.new_config)) {
      return device.current_state.new_config;
    }
  }

  return null;
}

export function getCurrentLocationState(device) {
  if (device && device.current_state && device.current_state.location) {
    // loop over all invalid measurement states and return the most recent one
    let current_state_timestamp = null;
    Object.keys(device.current_state.location).forEach((state_timestamp) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
    });
    if (current_state_timestamp) {
      return [current_state_timestamp, device.current_state.location[current_state_timestamp]];
    }
  }

  return [null, null];
}

export function hasCurrentInvalidMeasurementState(device) {
  if (
    device &&
    device.current_event &&
    device.current_event.iv &&
    !isEmpty(device.current_event.iv)
  ) {
    return true;
  }

  return false;
}

export function getCurrentIMEIState(device) {
  if (device && device.current_state && device.current_state.imei) {
    // loop over all invalid measurement states and return the most recent one
    let current_state_timestamp = null;
    Object.keys(device.current_state.imei).forEach((state_timestamp) => {
      if (!current_state_timestamp || current_state_timestamp < state_timestamp) {
        current_state_timestamp = state_timestamp;
      }
    });
    if (current_state_timestamp) {
      return device.current_state.imei[current_state_timestamp];
    }
  }

  return null;
}

export function getCurrentLANIPState(device) {
  if (device && device.current_state && device.current_state.device_state) {
    const deviceStates = device.current_state.device_state;
    const timestamps = Object.keys(deviceStates);

    // Sort timestamps in descending lexicographical order (works for same-length strings)
    timestamps.sort((a, b) => b.localeCompare(a));

    for (const timestamp of timestamps) {
      const entry = deviceStates[timestamp];
      if (entry && entry.lan_ip !== undefined) {
        // Return timestamp as a string to preserve precision, and the RSSI value
        return [timestamp, entry.lan_ip];
      }
    }
  }

  return [null, null];
}

export function getCurrentWANIPState(device) {
  if (device && device.current_state && device.current_state.device_state) {
    const deviceStates = device.current_state.device_state;
    const timestamps = Object.keys(deviceStates);

    // Sort timestamps in descending lexicographical order (works for same-length strings)
    timestamps.sort((a, b) => b.localeCompare(a));

    for (const timestamp of timestamps) {
      const entry = deviceStates[timestamp];
      if (entry && entry.wan_ip !== undefined) {
        // Return timestamp as a string to preserve precision, and the RSSI value
        return [timestamp, entry.wan_ip];
      }
    }
  }

  return [null, null];
}

export function configHasErrors(config) {
  if (config && config.errors) {
    return isEmpty(config.errors) === false;
  }

  return false;
}

export function getCurrentFWVersion(device_info) {
  if (device_info && device_info.fw_version) {
    return device_info.fw_version;
  }
  if (device_info && device_info.firmwareVersion) {
    return device_info.firmwareVersion;
  }

  return null;
}

export function getCurrentRSSIState(device) {
  if (device && device.current_state && device.current_state.device_state) {
    const deviceStates = device.current_state.device_state;
    const timestamps = Object.keys(deviceStates);

    // Sort timestamps in descending lexicographical order (works for same-length strings)
    timestamps.sort((a, b) => b.localeCompare(a));

    for (const timestamp of timestamps) {
      const entry = deviceStates[timestamp];
      if (entry && entry.rssi !== undefined) {
        // Return timestamp as a string to preserve precision, and the RSSI value
        return [timestamp, entry.rssi];
      }
    }
  }

  return [null, null];
}
